import React, { Component } from 'react';
import ToastMessage from './views/Dashboard/Toast';
import {baseUrl} from './services/urls.js';
export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
      sMessage:''
    }

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }


    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(
          () => {
            fetch(`${baseUrl}/checknetwork/durelogowhite.png`, {
              mode: 'no-cors',
              })
            .then(() => {
              this.setState({ isDisconnected: false ,sMessage:''}, () => {
                return clearInterval(webPing)
              });
            }).catch(() => this.setState({ isDisconnected: true }) )
          }, 2000);
        return;
      }

      return this.setState({ isDisconnected: true,sMessage:'Internet connection lost'});
    }

    render() {
      const { isDisconnected } = this.state;
      return (
        <div>
            {     
                isDisconnected && (
                    <ToastMessage Message={this.state.sMessage} closeModal={e => this.setState({sMessage:''})} hideBtn={true}/>
                )
            }
            <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  return NetworkDetector;
}