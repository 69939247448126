import React from "react";
const Login = React.lazy(() => import("../views/Login/Login"));
const Register = React.lazy(() => import("../views/Login/Register"));
const ForgotPassword = React.lazy(() =>
  import("../views/Login/ForgotPassword")
);
const FirstTimeLogin = React.lazy(() =>
  import("../views/Login/FirstTimeLogin")
);

const loginRoutes = [
  {
    loggedIn: true,
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    isFirstTime: true,
    path: "/firstTimeLogin",
    name: "firstTimeLogin",
    component: FirstTimeLogin,
  },
  {
    redirect: true,
    path: "/",
    to: "/login",
    name: "Login",
  },
];

export default loginRoutes;
