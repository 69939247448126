import { combineReducers } from "redux";
import * as actionType from "../actions/actionTypes";
import lsencryption from "../services/lsencryption";

const communityReducers = (state = {}, action) => {
  switch (action.type) {
    case actionType.SET_USER_DETAIL:
      return {
        ...state,
        ...action.payload,
        // dashboardSideBar: action.payload.roleComponents.dashboard_side_bar
      };
    case actionType.SET_LANGUAGE_LIST:
      return {
        ...state,
        languageList: action.payload,
      };
    case actionType.SET_SELECTED_NEARME:
      return {
        ...state,
        selectedNearme: action.payload,
      };
    case actionType.RESET_SELECTED_NEARME:
      return {
        ...state,
        selectedNearme: {},
      };
    default:
      // let oCom = JSON.parse(localStorage.getItem("community"));
      let oParam = lsencryption.get("community"),
        oCom = oParam ? JSON.parse(lsencryption.get("community")) : oParam;
      oCom = oCom ? { ...oCom } : {};
      return { ...state, ...oCom };
  }
};

/* const changeUUID = (state, action) =>{
    let oCom = JSON.parse(localStorage.getItem('community')),uuid = '';
    if(oCom){
      uuid = oCom.community.uuid;
    }
    return action.type === 'CHANGE_UUID' ? action.payload : uuid;
} */

const updateStateofApp = (state = false, action) => {
  if (action.type === actionType.RELOAD_PAGE) {
    return action.payload;
  }
  return state;
};

const selectedLang = (state = null, action) => {
  if (action.type === actionType.SELECTED_LANGUAGE) {
    return action.payload;
  }
  return state;
};

const updateTourguide = (state = null, action) => {
  if (action.type === actionType.TOUR_GUIDE) {
    return action.payload;
  }
  return state;
};

const updateNavbar = (state = null, action) => {
  if (action.type === actionType.Feedback_Mod) {
    return action.payload;
  }
  return state;
};

const isCloningAVailable = (state = null, action) => {
  if (action.type === actionType.CLONING_FLAG) {
    let oCom = JSON.parse(lsencryption.get("community")),
      { componentList } = oCom,
      { dashboardSections } = componentList,
      oSmartSetup = dashboardSections.filter(
        (e) => e.name === "Smart Set-Up"
      )[0];
    if (oSmartSetup) {
      let aChilds = oSmartSetup.childs,
        oSetUp = aChilds.filter((e) => e.name === "Setup")[0],
        bIsAvailble = oSetUp.childs.filter((e) => e.name === "Clone Data")[0];
      if (bIsAvailble && bIsAvailble.visible && bIsAvailble.isactive) {
        return true;
      }
    }
    return false;
  }
  return state;
};

const updateFilterFlag = (state = false, action) => {
  if (action.type === actionType.SETFILTER_FLAG) {
    return action.payload;
  }
  return state;
};
const updateFilterData = (state = null, action) => {
  if (action.type === actionType.SETFILTER_DATA) {
    return action.payload;
  }
  return state;
};

export default combineReducers({
  communityData: communityReducers,
  appState: updateStateofApp,
  selectedLang: selectedLang,
  updateTourguide: updateTourguide,
  updateNavbar: updateNavbar,
  isCloningAVailable: isCloningAVailable,
  pageFilterFlag: updateFilterFlag,
  globalFilters: updateFilterData,
});
